import React from 'react';
import { Link } from 'gatsby';

import Layout from '../components/Layout';
import SEO from '../components/SEO';

const IndexPage = () => (
  <Layout fullMenu>
    <SEO />
    <section id="banner">
      <div className="inner align-right">
        {/*<div className="logo">
          <span className="icon fa-horse"></span>
        </div>*/}
        <h2 className="onWhite">Pricing</h2>
        <p className="goAway">No highlight!</p>
      </div>
    </section>

    <section id="wrapper">
      <div className="wrapper last pricingPage">
        <div className="inner">
          <section className="features">
            <article className="pricingPage span12">
              <div className="table-wrapper">
                <table className="alt">
                  <thead>
                    <tr>
                      <th> </th>
                      <th className="align-center">
                        <div className="trial">
                          <div className="bafafu bafafuColor">Basic</div>
                          <div className="priceText">Top Hat</div>
                          <div className="emphasis alt bafafuColor">$5,200</div>
                        </div>
                      </th>
                      <th className="align-center">
                        <div className="trial">
                          <div className="bafafu bafafuColor">Fancy</div>
                          <div className="priceText">Top Hat</div>
                          <div className="emphasis alt bafafuColor">$6,750</div>
                        </div>
                      </th>
                      <th className="align-center">
                        <div className="trial">
                          <div className="bafafu alt bafafuColor">
                            Full Unicorn
                          </div>
                          <div className="priceText">Treatment</div>
                          <div className="emphasis bafafuColor">$7,800</div>
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="priceText">
                        Responsive, two-page site design
                      </td>
                      <td className="align-center">
                        <i className="icon fa-check" />
                      </td>
                      <td className="align-center">
                        <i className="icon fa-check" />
                      </td>
                      <td className="align-center">
                        <i className="icon fa-check" />
                      </td>
                    </tr>
                    <tr>
                      <td className="priceText">SEO & Site analytics</td>
                      <td className="align-center">
                        <i className="icon fa-check" />
                      </td>
                      <td className="align-center">
                        <i className="icon fa-check" />
                      </td>
                      <td className="align-center">
                        <i className="icon fa-check" />
                      </td>
                    </tr>
                    <tr>
                      <td className="priceText">Optional blog capabilities</td>
                      <td className="align-center">
                        <i className="icon fa-check" />
                      </td>
                      <td className="align-center">
                        <i className="icon fa-check" />
                      </td>
                      <td className="align-center">
                        <i className="icon fa-check" />
                      </td>
                    </tr>
                    <tr>
                      <td className="priceText">E-commerce Integration</td>
                      <td className="align-center">
                        <i className="icon fa-times" />
                      </td>
                      <td className="align-center">
                        <i className="icon fa-check" />
                      </td>
                      <td className="align-center">
                        <i className="icon fa-check" />
                      </td>
                    </tr>
                    <tr>
                      <td className="priceText">Domain registration</td>
                      <td className="align-center">
                        <i className="icon fa-times" />
                      </td>
                      <td className="align-center">
                        <i className="icon fa-times" />
                      </td>
                      <td className="align-center">
                        <i className="icon fa-check" />
                      </td>
                    </tr>
                    <tr>
                      <td className="priceText">Commissioned art assets</td>
                      <td className="align-center">
                        <i className="icon fa-times" />
                      </td>
                      <td className="align-center">
                        <i className="icon fa-times" />
                      </td>
                      <td className="align-center">
                        <i className="icon fa-check" />
                      </td>
                    </tr>
                    <tr>
                      <td className="priceText">
                        Ownership of commissioned assets
                      </td>
                      <td className="align-center">
                        <i className="icon fa-times" />
                      </td>
                      <td className="align-center">
                        <i className="icon fa-times" />
                      </td>
                      <td className="align-center">
                        <i className="icon fa-check" />
                      </td>
                    </tr>
                    <tr>
                      <td className="priceText">One year of THUP Services</td>
                      <td className="align-center">
                        <i className="icon fa-times" />
                      </td>
                      <td className="align-center">
                        <i className="icon fa-times" />
                      </td>
                      <td className="align-center">
                        <i className="icon fa-check" />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </article>
            {/*<article className="pricingPage">
              <h3 className="major">Just the Top Hat - $5,200</h3>
              <p>
                <ul className="alt">
                  <li>
                    <i className="icon fa-check" /> Responsive, two-page site
                    design
                  </li>
                  <li>
                    <i className="icon fa-check" /> SEO & Site analytics
                  </li>
                  <li>
                    <i className="icon fa-check" /> Optional blog capabilities
                  </li>
                  <li>
                    <i className="icon fa-times" /> Domain registration
                  </li>
                  <li>
                    <i className="icon fa-times" /> Art assets creation
                  </li>
                  <li>
                    <i className="icon fa-times" /> Ownership of commissioned
                    assets
                  </li>
                  <li>
                    <i className="icon fa-times" /> One year of THUP Services
                  </li>
                </ul>
              </p>
            </article>
            <article className="pricingPage">
              <h3 className="major">Full Unicorn Treatment - $6,240</h3>
              <p>
                <ul className="alt">
                  <li>
                    <i className="icon fa-check" /> Responsive, two-page site
                    design
                  </li>
                  <li>
                    <i className="icon fa-check" /> SEO & Site analytics
                  </li>
                  <li>
                    <i className="icon fa-check" /> Optional blog capabilities
                  </li>
                  <li>
                    <i className="icon fa-check" /> Domain registration
                  </li>
                  <li>
                    <i className="icon fa-check" /> Art assets creation
                  </li>
                  <li>
                    <i className="icon fa-check" /> Ownership of commissioned
                    assets
                  </li>
                  <li>
                    <i className="icon fa-check" /> One year of THUP Services
                  </li>
                </ul>
              </p>
      </article>*/}
            <article className="span12 pricingPage">
              <h3 className="major align-center bafafuColor">Power Ups!</h3>
              <p>
                <ul className="alt">
                  <li>
                    <b className="trial">THUP Services - $60/month</b>
                    <span className="align-right">
                      Basic site maintenance from our team.
                    </span>
                  </li>
                  <br />
                  <li>
                    <b className="trial">An Extra Page - $500-$1,000</b>
                    <span className="align-right">
                      Want a forum? A contact page? Tell us!
                    </span>
                  </li>
                  <br />
                  <li>
                    <b className="trial">An Applet - $50-$150</b>
                    <span className="align-right">
                      Some kind of fancy plug-in for your site!
                    </span>
                  </li>
                  <br />
                  <li>
                    <b className="trial">Asset Creation - $300</b>
                    <span className="align-right">
                      Unicorn-summoned content for your site.
                    </span>
                  </li>
                  <br />
                  <li>
                    <b className="trial">Warlock Services - $80/hour</b>
                    <span className="align-right">
                      Extra attention from the website warlock.
                    </span>
                  </li>
                </ul>
              </p>
            </article>
            <article className="span12 pricingPage-alt">
              <div className="align-center">
                <button className="btn-alt fit">
                  <Link to="/consultation">
                    <p className="major align-center">
                      <b className="bafafuColor">
                        Schedule a Free Consultation with Us!
                      </b>
                    </p>
                  </Link>
                </button>
              </div>
            </article>
          </section>
        </div>
      </div>
    </section>
  </Layout>
);

export default IndexPage;
