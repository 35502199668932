import React from 'react';
import Helmet from 'react-helmet';

export default function SEO() {
  return (
    <Helmet
      title='Tophat Unicorn Productions | "We build websites!"'
      meta={[
        { name: 'description', content: 'Website Developers' },
        {
          name: 'keywords',
          content:
            'website, builders, development, unicorn, tophat, affordable, site, website, web page, web development, website development, web page development, web developer, web design, webdesign, website design, web page design, web designing, web designer, web design and development, web development and design, website design and development, website development and design, web design company, web developer company, freelance web developer, freelance web designer, simple website design, responsive web design, easy website, create website, website builder, website maker, professional website, hands free website, make your own website, create your own website, cheap website builder, hire website design, hire website designer, hire website creator, hire website developer, build a site, build a website, build a webpage, business site builder, business website builder, business website, business website create, Phoenix developer, Scottsdale developer, Glendale developer, Peoria developer, Storm Arias, Jonathan Moss',
        },
      ]}
    />
  );
}
